import {Controller} from "@hotwired/stimulus"
import {isPresent} from "./lib/utils"

export default class extends Controller {
  static targets = [
    'userid',
    'scouttype',
    'brandid',
    'storeid',
    'sender',
    'title',
    'content',
    'offerids',
    'submit',
  ]

  static values = {
    userid: String,
    scouttype: String,
    brandid: String,
    storeid: String,
    sender: String,
    title: String,
    content: String,
    offerids: String
  };

  connect() {
    this._updateAllTargets();
    this._validate();
  }

  useridValueChanged() {
    this._updateAllTargets();
    this._validate();
  }

  scouttypeValueChanged() {
    this._updateAllTargets();
    this._validate();
  }

  brandidValueChanged() {
    this._updateAllTargets();
    this._validate();
  }

  storeidValueChanged() {
    this._updateAllTargets();
    this._validate();
  }

  senderValueChanged() {
    this._updateAllTargets();
    this._validate();
  }

  titleValueChanged() {
    this._updateAllTargets();
    this._validate();
  }

  contentValueChanged() {
    this._updateAllTargets();
    this._validate();
  }

  offeridsValueChanged() {
    this._updateAllTargets();
    this._validate();
  }

  handleOnSubmit(e) {
    if (!confirm('このスカウトを送信しますか？')) {
      e.preventDefault();
    }
  }

  handleOnPremiumMemberScoutSubmit(e) {
    if (!confirm('このスカウトを送信しますか？')) {
      e.preventDefault();
    }
  }



  setInitialValue(userid, scouttype, brandid, storeid, sender, title, content, offerids) {

    Array.from(document.getElementsByName(`scout-type-${userid}`)).forEach((element) => {
      if (element.value === scouttype) {
        element.checked = true
        $(element).trigger('change')

        if (scouttype === 'brand') {
          const selectInput = $(element.parentElement.parentElement).find('.js-scout__brand-selector select')[0]

          Array.from(selectInput.children).forEach(function (optionElement) {
            if (optionElement.value === brandid) {

              optionElement.selected = true
              $(selectInput).trigger('change')
            }
          })
        } else if (scouttype === 'store') {
          const selectTrigger = $('.js-scout__store-selector')
          const selectBrandInput = $(element.parentElement.parentElement).find('.js-scout__store-selector select#brand_id')[0]
          const selectStoreInput = $(element.parentElement.parentElement).find('.js-scout__store-selector select#store_id')[0]
          Array.from(selectBrandInput.children).forEach((optionElement) => {
            if (optionElement.value === brandid) {
              optionElement.selected = true
              $(selectTrigger).trigger('change')
            }
          })

          // 店舗を選択できるようにする
          const storeElement = $($($(element.parentElement.parentElement).find('.js-store-search')[0]).find('.js-store-search__store'))
          storeElement.removeClass('is-disabled')
          storeElement.removeAttr('readonly')
          storeElement.removeAttr('disabled')

          // 店舗のロードが終わるまで待つ。
          $(selectTrigger).find('.js-store-search__brand').one('change:after', () => {
            Array.from(selectStoreInput.children).forEach((optionElement) => {
              if (optionElement.value === storeid) {
                optionElement.selected = true
              }
            })
          });

          // ブランド後のイベントを発火させる
          $(selectTrigger).find('.js-store-search__brand').trigger('change')

        }
      }
    })

    this.useridValue = userid;
    this.scouttypeValue = scouttype;
    this.brandidValue = brandid;
    this.storeidValue = storeid;
    this.senderValue = sender;
    this.titleValue = title;
    this.contentValue = content;
    this.offeridsValue = offerids;


    this._updateAllTargets();
    this._validate();
  }


  _updateAllTargets() {
    this.useridTarget.value = this.useridValue;
    this.scouttypeTarget.value = this.scouttypeValue;
    this.brandidTarget.value = this.brandidValue;
    this.storeidTarget.value = this.storeidValue;
    this.senderTarget.value = this.senderValue;
    this.titleTarget.value = this.titleValue;
    this.contentTarget.value = this.contentValue;
    this.offeridsTarget.value = this.offeridsValue;
  }

  _validate() {
    const hasScoutType = ['company', 'brand', 'store'].includes(this.scouttypeTarget.value);
    const isValidForm = (() => {
      const hasValue = this.useridTarget.value &&
                        this.senderTarget.value &&
                        this.titleTarget.value &&
                        this.contentTarget.value &&
                        this.offeridsTarget.value;
      const notSpaceOnly = this.useridTarget.value.trim() &&
                            this.senderTarget.value.trim() &&
                            this.titleTarget.value.trim() &&
                            this.contentTarget.value.trim() &&
                            this.offeridsTarget.value.trim();
      return hasValue && notSpaceOnly;
    })()
    const isValidScoutType = (() => {
      const scoutType = this.scouttypeTarget.value;
      if (scoutType == 'company') {
        return true;
      } else if (scoutType == 'brand') {
        return isPresent(this.brandidTarget.value);
      } else if (scoutType == 'store') {
        return isPresent(this.brandidTarget.value) && isPresent(this.storeidTarget.value);
      }
    })()

    // 要素が存在しない場合は処理を中断
    try {
      if (hasScoutType && isValidScoutType && isValidForm) {
        this.submitTarget.disabled = false;
        this.submitTarget.classList.remove('is-disabled');
      } else {
        this.submitTarget.disabled = true;
        this.submitTarget.classList.add('is-disabled');
      }
    } catch (e) {
      // console?.error(e);
    }

  }
}